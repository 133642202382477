import { Box, Skeleton, Typography } from "@mui/material";
import theme from "lib/theme";
import useCurrentUser from "hooks/users/useCurrentUser";
import useHome from "hooks/home/useHome";
import QuickInsights from "./QuickInsights";
import QuickStats from "./QuickStats";
import EnrolledAssets from "./EnrolledAssets";

export default function Home() {
  const { currentUser } = useCurrentUser();
  const { homeInfo } = useHome();

  return (
    <Box>
      <Typography variant="h4" fontWeight="bold">
        {!currentUser.firstName && <Skeleton variant="text" height={40} width={300} />}
        {currentUser.firstName && `Hi there, ${currentUser.firstName}!`}
      </Typography>

      {homeInfo?.enrolledAssetCount ? (
        <Box
          sx={{
            mt: 4,
            mb: 4,
            [theme.breakpoints.down("sm")]: {
              mt: 2,
              mb: 2,
            },
          }}
        >
          <EnrolledAssets enrolledAssetsCount={homeInfo.enrolledAssetCount} />
        </Box>
      ) : null}

      <Box mt={2}>
        <QuickInsights homeInfo={homeInfo} />
      </Box>

      <Box
        sx={{
          mt: 4,
          [theme.breakpoints.down("sm")]: {
            mt: 2,
          },
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          Quick stats
        </Typography>

        <Typography variant="body1" mt={1}>
          Take a quick look at some insightful summaries and stats that we pulled together just for you.
        </Typography>

        <Box mt={2}>
          <QuickStats homeInfo={homeInfo} />
        </Box>
      </Box>
    </Box>
  );
}
