import Nav from "components/AppLayout/Nav";
import {
  Box,
  Container,
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItemButton as MuiListItem,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText as MuiListItemText,
  ListItemTextProps,
  styled,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { ReactComponent as ShippingIcon } from "assets/icons/Shipping.svg";
import DevicesIcon from "@mui/icons-material/Devices";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import ApiIcon from "@mui/icons-material/Api";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import theme from "lib/theme";
import PlanFeatureWrapper from "components/PlanFeatureWrapper";
import TypeformFeedbackButton from "./TypeformFeedbackButton";

const ListItem = styled(MuiListItem)<ListItemButtonProps>(({ theme }) => ({
  "&.Mui-selected": { borderRight: `4px solid ${theme.palette.primary.main}` },
})) as typeof MuiListItem;

const ListItemText = styled(MuiListItemText)<ListItemTextProps>(({ theme }) => ({
  ".MuiTypography-root": { fontWeight: "bold" },
}));

const drawerWidth = 300;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    top: "66px",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
    }),
    [theme.breakpoints.down("lg")]: { top: `0 !important`, ...(!open && { width: 0 }) },
  },
}));

interface ListItemsProps {
  handleToggle(): void;
}

export function ListItems({ handleToggle }: ListItemsProps) {
  const { pathname } = useLocation();
  const isTabletView = useMediaQuery(theme.breakpoints.down("lg"));

  function handleItemClick() {
    if (isTabletView) {
      handleToggle();
    }
  }

  return (
    <List>
      {" "}
      <ListItem onClick={handleItemClick} selected={pathname === "/"} component={Link} to="/">
        {" "}
        <ListItemIcon>
          {" "}
          <HomeOutlinedIcon color="primary" />{" "}
        </ListItemIcon>{" "}
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem onClick={handleItemClick} selected={pathname.includes("/pickups")} component={Link} to="/pickups">
        <ListItemIcon>
          <LocalShippingOutlinedIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Pickups" />
      </ListItem>
      <ListItem
        onClick={handleItemClick}
        selected={pathname.includes("/assets")}
        component={Link}
        to="/assets?page%5Bnumber%5D=1&page%5Bsize%5D=25&filter%5B%5D=state%2Cis_any_of%2Cretired"
      >
        <ListItemIcon>
          <DevicesIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Assets" />
      </ListItem>
      <ListItem
        onClick={handleItemClick}
        selected={pathname.startsWith("/reporting-and-documents")}
        component={Link}
        to="/reporting-and-documents"
      >
        <ListItemIcon>
          <ListAltOutlinedIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Reporting & Documents" />
      </ListItem>
      <ListItem
        onClick={handleItemClick}
        selected={pathname.startsWith("/donations-and-earnings")}
        component={Link}
        to="/donations-and-earnings"
      >
        <ListItemIcon>
          <VolunteerActivismIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Donations & Earnings" />
      </ListItem>
      <ListItem
        onClick={handleItemClick}
        selected={pathname.startsWith("/retrievals")}
        component={Link}
        to="/retrievals/send-boxes"
      >
        <ListItemIcon>
          <ShippingIcon />
        </ListItemIcon>
        <ListItemText primary="Retrievals" />
      </ListItem>
      {
        <PlanFeatureWrapper
          feature="api"
          enabledElement={
            <ListItem onClick={handleItemClick} selected={pathname.startsWith("/api")} component={Link} to="/api">
              <ListItemIcon>
                <ApiIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="API" />
            </ListItem>
          }
          disabledElement={
            <ListItem onClick={handleItemClick} selected={pathname.startsWith("/api")} component={Link} to="/api">
              <ListItemIcon>
                <ApiIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="API" />
            </ListItem>
          }
          grandfatheredElement={
            <ListItem onClick={handleItemClick} selected={pathname.startsWith("/api")} component={Link} to="/api">
              <ListItemIcon>
                <ApiIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="API" />
            </ListItem>
          }
        />
      }
      <ListItem
        onClick={handleItemClick}
        selected={pathname.startsWith("/company-settings")}
        component={Link}
        to="/company-settings"
      >
        <ListItemIcon>
          <SettingsIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Company Settings" />
      </ListItem>
    </List>
  );
}

interface AppLayoutProps {
  children: React.ReactNode;
  noPadding?: boolean;
  container?: boolean;
}

function AppLayout({ children, noPadding = false, container = false }: AppLayoutProps) {
  const { loggedIn } = useAuth();
  const isTabletView = useMediaQuery(theme.breakpoints.down("lg"));
  const [open, setOpen] = useState(!isTabletView);

  function handleToggle() {
    setOpen((open) => !open);
  }

  useEffect(() => {
    setOpen(!isTabletView);
  }, [isTabletView, setOpen]);

  return (
    <div>
      <Nav open={open} handleToggle={handleToggle} />

      {loggedIn ? (
        <>
          <Drawer data-testid="app-drawer" variant="permanent" open={open} aria-expanded={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton
                color="primary"
                onClick={handleToggle}
                aria-label={open ? "Collapse Sidebar" : "Expand Sidebar"}
              >
                {open ? <MenuOpenIcon /> : <MenuIcon />}
              </IconButton>
            </Toolbar>

            <ListItems handleToggle={handleToggle}></ListItems>
          </Drawer>

          <TypeformFeedbackButton />

          <Box
            sx={{
              padding: noPadding ? "none" : 6,
              minHeight: "calc(100vh - 64px)",
              marginLeft: open ? `${drawerWidth}px` : 7,
              marginTop: "64px",
              [theme.breakpoints.down("lg")]: {
                marginLeft: 0,
                padding: noPadding ? "none" : 3,
              },
              [theme.breakpoints.down("sm")]: {
                padding: noPadding ? "none" : 2,
                minHeight: "calc(100vh - 58px)",
                marginTop: "58px",
              },
            }}
          >
            {container ? (
              <Container sx={{ [theme.breakpoints.down("md")]: { px: 0 } }}>{children}</Container>
            ) : (
              children
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              background: "#DDF3FC",
              marginTop: "64px",
              minHeight: "calc(100vh - 64px)",
              [theme.breakpoints.down("sm")]: {
                minHeight: "calc(100vh - 58px)",
                marginTop: "58px",
              },
            }}
          >
            <Box
              sx={{
                padding: 6,
                marginLeft: `${drawerWidth}px`,
                background: "#F9FBFD",
                minHeight: "calc(100vh - 64px)",
                [theme.breakpoints.down("lg")]: {
                  marginLeft: 0,
                  padding: noPadding ? "none" : 3,
                },
                [theme.breakpoints.down("sm")]: {
                  minHeight: "calc(100vh - 58px)",
                  marginTop: "58px",
                },
              }}
            >
              <Container>{children}</Container>
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}

export default AppLayout;
