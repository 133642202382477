import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { api } from "services/api.service";
import TokenService from "services/token.service";
import useAnalytics from "./analytics/useAnalytics";
import { useAlert } from "./alerts/useAlert";
import Cookies from "js-cookie";

type AuthContextValue = {
  loggedIn: Boolean;
  setLoggedIn: (loggedIn: Boolean) => void;
};
type Tokens = {
  accessToken: string;
  client: string;
  uid: string;
  expiry: string;
};

export const AuthContext = createContext<AuthContextValue>({
  loggedIn: false,
  setLoggedIn: () => {},
});

function setTokens(tokens: Tokens) {
  TokenService.set("accessToken", tokens.accessToken);
  TokenService.set("client", tokens.client);
  TokenService.set("uid", tokens.uid);
  TokenService.set("expiry", tokens.expiry);
}

function getTokensFromUrl(search: string): Tokens {
  const params = new URLSearchParams(search);
  const accessToken = params.get("access-token") || "";
  const client = params.get("client") || "";
  const uid = params.get("uid") || "";
  const expiry = params.get("expiry") || "";

  return {
    accessToken,
    client,
    uid,
    expiry,
  };
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [loggedIn, setLoggedIn] = useState<Boolean>(Boolean(TokenService.get("accessToken")));
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { createAnalyticsEvent } = useAnalytics();

  const value = useMemo(
    () => ({
      loggedIn,
      setLoggedIn,
    }),
    [loggedIn]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    if (queryParams.has("email")) {
      const email = queryParams.get("email");
      queryParams.delete("email");

      api.get("/organizations/find", { params: { email: email } }).then(({ data: { organizationUrl } }) => {
        const newUrl = `${organizationUrl}${pathname}${queryParams.toString()}`;
        window.location.href = newUrl;
      });
    }

    if (!pathname.includes("accept-invitation")) {
      if (search.includes("access-token")) {
        const urlTokens = getTokensFromUrl(search);
        setTokens(urlTokens);

        setLoggedIn(true);
        createAnalyticsEvent("sso_logged_in");
      }

      if (loggedIn && pathname.includes("login")) {
        const redirectPath = Cookies.get("location");
        Cookies.remove("location");
        navigate(redirectPath ?? "/");
      } else if (
        !loggedIn &&
        !pathname.includes("reset-password") &&
        !pathname.includes("create-reset-password") &&
        !pathname.includes("find-organization") &&
        !pathname.includes("no-backend-connection")
      ) {
        if (!pathname.includes("login")) {
          const pathAndSearch = `${pathname}${queryParams.toString()}`;
          Cookies.set("location", pathAndSearch);
        }
        navigate("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const { alertInfo } = useAlert();
  const { search } = useLocation();
  const [searchParams] = useSearchParams(search);
  const { createAnalyticsEvent } = useAnalytics();

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const response = await api.post("/auth/sign_in", { email, password });

      TokenService.set("accessToken", response.headers["access-token"]);
      TokenService.set("client", response.headers["client"]);
      TokenService.set("uid", response.headers["uid"]);
      TokenService.set("expiry", response.headers["expiry"]);

      setLoggedIn(true);
      createAnalyticsEvent("logged_in");
    },
    [setLoggedIn]
  );

  const createResetPassword = useCallback(async ({ email }: { email: string }) => {
    const redirectUrl = `${window.location.origin}/reset-password`;
    await api.post("/auth/password", { email: email, redirect_url: redirectUrl });
  }, []);

  const resetPassword = useCallback(
    async ({ passwordConfirmation, password }: { passwordConfirmation: string; password: string }) => {
      const resetPasswordToken = searchParams.get("reset_password_token");
      await api
        .put(`/auth/password`, {
          passwordConfirmation,
          password,
          resetPasswordToken: resetPasswordToken,
        })
        .then(() => {
          alertInfo("You've successfully reset your password!", {
            vertical: "bottom",
            horizontal: "right",
          });
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        });
    },
    []
  );

  const findOrganization = useCallback(
    (email: string) =>
      api.get("/organizations/find", { params: { email } }).then(({ data: { organizationUrl } }) => {
        window.location.href = organizationUrl;
      }),
    []
  );

  return {
    login,
    loggedIn,
    createResetPassword,
    resetPassword,
    findOrganization,
  };
}
