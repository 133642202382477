import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { api } from "services/api.service";
import useAnalytics from "hooks/analytics/useAnalytics";
import { Document } from "types/document";
import TokenService from "services/token.service";

export default function useDocuments() {
  const [searchParams] = useSearchParams();
  const [meta, setMeta] = useState<{ totalPages: number; count: number }>({ totalPages: 0, count: 0 });
  const [documents, setDocuments] = useState<Document[]>();
  const { createAnalyticsEvent } = useAnalytics();

  useEffect(() => {
    api.get(`/documents?${searchParams.toString()}`).then((res) => {
      setDocuments(res.data.documents);
      setMeta(res.data.meta);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const downloadDocument = (uuid: string, name: string, type: string, fileType: string) => {
    const downloadEvent = type ? `downloaded_cod-${type}` : "downloaded_cod";
    createAnalyticsEvent(downloadEvent);
    api.get(`/documents/${uuid}`).then(({ data: { document: doc } }) => {
      if (doc.url.includes(document.location.host)) return downloadZippedDocs(doc.url, name, fileType);
      fetch(doc.url).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `${name}.${fileType}`;
          a.click();
        });
      });
    });
  };

  const downloadZippedDocs = (url: string, name: string, fileType: string) => {
    fetch(url, {
      credentials: "include",
      headers: {
        "access-token": TokenService.get("accessToken") || "",
        client: TokenService.get("client") || "",
        uid: TokenService.get("uid") || "",
        expiry: TokenService.get("expiry") || "",
        "token-type": "Bearer",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${name}.${fileType}`;
        a.click();
      });
    });
  };

  return {
    documents,
    meta,
    downloadDocument,
  };
}
