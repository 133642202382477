import { Menu } from "components/common/Menu";
import { IconButton, Tooltip } from "@mui/material";
import useCurrentUserPermission from "hooks/useCurrentUserPermission";
import { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { RetiredAssetModal } from "./RetiredAssetModal";
import { Asset } from "types/asset";
import { NotFoundAssetModal } from "./NotFoundAssetModal";
import { History, CheckCircle, LockClock } from "@mui/icons-material";
export function ActionCell({ asset, refetch }: any) {
  const [retiredAssetOpen, setRetiredAssetOpen] = useState(false);
  const [notFoundOpen, setNotFoundOpen] = useState(false);

  const { hasPermission } = useCurrentUserPermission();

  const showActions = (asset: Asset) => {
    if (asset.state === "live" && asset.status !== "Pending Pickup") {
      return true;
    } else {
      return false;
    }
  };

  const downloadCod = (url: string, name: string, fileType: string) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        let fileUrl = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = fileUrl;
        a.download = `${name}.${fileType}`;
        a.click();
        window.URL.revokeObjectURL(fileUrl); // Cleanup
      });
    });
  };

  return (
    <>
      <Menu
        button={(props) => (
          <Tooltip
            title={
              hasPermission("assets")
                ? ""
                : "You do not have permissions for this action, please contact your administrator for permission."
            }
          >
            <span>
              <IconButton {...props} disabled={!hasPermission("assets")}>
                <MoreHorizIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        actions={[
          {
            label: (
              <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <History fontSize="small" color="disabled" />
                View Asset History
              </span>
            ),
            to: `/assets/${asset.uuid}/history`,
          },
          {
            label: (
              <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <LockClock fontSize="small" color="disabled" />
                Can't release?
              </span>
            ),
            onClick: () => setNotFoundOpen(true),
          },
          ...(asset.certificateOfDestructionUrl
            ? [
                {
                  label: (
                    <a
                      href={asset.certificateOfDestructionUrl}
                      download="certificate-of-destruction.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        width: "100%",
                      }}
                    >
                      <CheckCircle fontSize="small" color="disabled" />
                      Download COD
                    </a>
                  ),
                },
              ]
            : []),
          ...(showActions(asset) ? [{ label: `Check Out Asset`, to: `/assets/${asset.uuid}/checkout` }] : []),
          ...(showActions(asset) ? [{ label: `Retire Asset`, onClick: () => setRetiredAssetOpen(true) }] : []),
        ]}
      />

      <RetiredAssetModal
        open={retiredAssetOpen}
        setOpen={setRetiredAssetOpen}
        uuid={asset.uuid || ""}
        refetch={refetch}
      />
      <NotFoundAssetModal open={notFoundOpen} setOpen={setNotFoundOpen} uuid={asset.uuid || ""} refetch={refetch} />
    </>
  );
}
