import { GridSelectionModel } from "@mui/x-data-grid";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useAlert } from "hooks/alerts/useAlert";
import useSearchParamsOperations from "hooks/useSearchParamsOperations";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { api } from "services/api.service";
import { Asset } from "types/asset";

type SerialNumberPayload = {
  asset_ids?: GridRowId[];
  select_all_flag?: boolean;
  apple_serials?: boolean;
};

export default function useAssets() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [searchParams] = useSearchParams();
  const [assets, setAssets] = useState<Asset[]>([]);
  const [meta, setMeta] = useState<{ totalPages: number; count: number }>({ totalPages: 0, count: 0 });
  const [selectedAssetIds, setSelectedAssetIds] = useState<GridSelectionModel>([]);
  const [selectedAssets, setSelectedAssets] = useState<Asset[]>([]);
  const { deleteFieldInArrayUsingPrefix } = useSearchParamsOperations();
  const { alertSuccess, alertNeutral, alertError } = useAlert();
  const [statuses, setStatuses] = useState<string[]>([]);
  const [selectAllFlag, setSelectAllFlag] = useState(false);

  const fetchAssets = useMemo(
    () =>
      debounce((searchParams: URLSearchParams) => {
        setIsError(false);
        setIsLoading(true);
        const args = searchParams.toString()
          ? searchParams.toString()
          : "page%5Bnumber%5D=1&page%5Bsize%5D=25&filter%5B%5D=state%2Cis_any_of%2Clive%2Cpending_pickup";
        api
          .get<{ assets: Asset[]; meta: { totalPages: number; count: number } }>(`/assets?${args.toString()}`)
          .then((res) => {
            setAssets(res.data.assets);
            setMeta(res.data.meta);
            setIsLoading(false);
          })
          .catch(() => {
            setIsError(true);
            setIsLoading(false);
          });
      }, 500),
    []
  );

  const fetchStatuses = useCallback(() => {
    setIsLoading(true);
    api
      .get<{ statuses: string[] }>(`/assets/statuses`)
      .then((res) => {
        setStatuses(res.data.statuses);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const refetch = useCallback(() => {
    fetchAssets(searchParams);
  }, [searchParams]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    fetchStatuses();
  }, []);

  useEffect(() => {
    setSelectedAssets(assets.filter(({ id }) => selectedAssetIds.includes(id)));
  }, [selectedAssetIds, assets]);

  const bulkUnenroll = () => {
    api.post(`/unenroll_assets`, { assetIds: selectedAssetIds, selectAllFlag }).then(() => {
      deleteFieldInArrayUsingPrefix("filter[]", "status,is_any_of");
      alertSuccess("Assets are now being verified for repurposing.");
      setSelectAllFlag(false);
      setSelectedAssetIds([]);
      refetch();
    });
  };

  const bulkPendingPickup = () => {
    api.post(`/pending_pickup_assets`, { assetIds: selectedAssetIds }).then(() => {
      deleteFieldInArrayUsingPrefix("filter[]", "status,is_any_of");
      alertSuccess("Assets are now Pending Pickup, schedule your pickup today!");
      refetch();
    });
  };

  const getAllSerialsNumbers = useCallback(
    (asset_ids?: GridRowId[], selectAllFlag?: boolean, appleSerials?: boolean) => {
      const payload: SerialNumberPayload = {};
      if (selectAllFlag) payload.select_all_flag = true;
      if (appleSerials) payload.apple_serials = true;
      if (!selectAllFlag && asset_ids && asset_ids.length > 0) payload.asset_ids = asset_ids;

      return api.get("/export_serial_numbers", { params: payload }).then(async ({ data }) => {
        const serialNumbers = await data.serialNumbers.join();
        navigator.clipboard
          .writeText(serialNumbers)
          .then(() => {
            alertNeutral("Copied to clipboard");
          })
          .catch(() => {
            alertError("Failed to copy serial numbers. Please try again");
          });
      });
    },
    [selectAllFlag]
  );
  return {
    isLoading,
    setIsLoading,
    isError,
    assets,
    setAssets,
    meta,
    statuses,
    refetch,
    setSelectedAssetIds,
    selectedAssetIds,
    selectedAssets,
    bulkUnenroll,
    bulkPendingPickup,
    setSelectAllFlag,
    selectAllFlag,
    getAllSerialsNumbers,
  };
}
