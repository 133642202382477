import { Person } from "./person";
import { Location } from "./location";
import { Organization } from "./organization";
import { Pickup } from "./pickup";

export interface Asset {
  id: number;
  uuid: string;
  state: string;
  status: string;
  serialNumber: string;
  assetTag: string;
  model: string;
  manufacturer: string;
  vendor: string;
  accessories: string[];
  purchaseDate: string;
  expirationDate: string;
  endOfLifeDate: string;
  enrolledStatus?: string;
  deviceType: string;
  damageNotes?: string;
  organization?: Organization;
  location?: Location;
  locationId?: string;
  pickup?: Pickup;
  person?: Person;
  certificateOfDestructionUrl?: string;
}

export const defaultAsset = {
  id: 0,
  uuid: "",
  state: "",
  status: "",
  serialNumber: "",
  assetTag: "",
  model: "",
  manufacturer: "",
  vendor: "",
  accessories: [],
  purchaseDate: "",
  expirationDate: "",
  endOfLifeDate: "",
  enrolledStatus: "",
  deviceType: "",
  damageNotes: "",
};

export interface AssetOrganization {
  uuid: string;
  name: string;
}

export interface AssetPickup {
  uuid: string;
  scheduled_at: string;
}
