/* istanbul ignore file */

import Cookies from "js-cookie";

const TokenService = {
  set: function (name: string, token: string) {
    const expires = { expires: 90 };
    Cookies.set(name, token, expires);
  },

  remove: function (name: string) {
    Cookies.remove(name);
    localStorage.removeItem("sandbox");
  },

  get: function (name: string): string | undefined {
    return Cookies.get(name);
  },
};

export default TokenService;
